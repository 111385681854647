<template>
  <div>
    <Dropdown
      ref="dropdown"
      label="designation"
      :options="growthStages"
      @input="onInput"
      :value="value"
      :required="isPulveFamily"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

import Dropdown from '@/components/activity/edit/Dropdown.vue';

export default {
  mixins: [DetailUpdateMixin],
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters({
      inputForm: "tour/inputForm"
    }),
    isPulveFamily() {
      return this.currentEditedEntry.familyId == 7  || this.currentEntry.activityType_code == "pulve"
    },
    growthStages() {
      return this.inputForm?.growthStages || []
    },
    value() {
      // S'il y'a un seul stade de croissance, il est directement sélectionné
      if (this.growthStages.length === 1) {
        return this.growthStages[0]
      }
      return this.growthStages.find(s => s.id == (this.currentEditedEntry.growthStage || this.currentEntry.growthStage))
    }
  },
  mounted() {
    this.$refs["dropdown"].setValue(this.value)
    this.onInput(this.value)
  },
  methods: {
    onInput(growthStage) {

      this.$store.dispatch(this.storeEditAction, {
        growthStage: growthStage ? growthStage.id : null,
      });
    }
  },
  watch: {
    value() {
      this.$refs["dropdown"].setValue(this.value)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
